import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import { useGetIdentity, useLogout, useTranslate } from '@refinedev/core'
import {
  Avatar,
  Dropdown,
  Layout as AntdLayout,
  MenuProps,
  Space,
  theme,
  Typography,
} from 'antd'
import { useEffect } from 'react'
import { ICurrentUser } from 'interfaces'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import './styles.css'

const { Text } = Typography
const { useToken } = theme

const ProfileMenu: React.FC<{ user: ICurrentUser }> = ({ user }) => {
  const t = useTranslate()
  const { mutate: mutateLogout } = useLogout()

  const items: MenuProps['items'] = [
    {
      label: <Text>{user.email}</Text>,
      disabled: true,
      key: '0',
    },
    {
      label: <Link to={'/profile'}>{t('components.profile_menu.settings')}</Link>,
      key: '1',
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div onClick={() => mutateLogout()}>{t('components.profile_menu.logout')}</div>
      ),
      key: '2',
      danger: true,
      icon: <LogoutOutlined onClick={() => mutateLogout()} />,
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['hover']}>
      <Avatar size="default">{user.email[0]}</Avatar>
    </Dropdown>
  )
}

export const Header: React.FC = () => {
  const { token } = useToken()
  const { data: user } = useGetIdentity<ICurrentUser>()

  const partnerDealing = user?.dealings?.find(
    (dealing) => dealing.role.code === 'partner_admin'
  )

  useEffect(() => {
    user?.timezone && dayjs.tz.setDefault(user.timezone)
    console.log('user.timezone', user?.timezone)
  }, [user])

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: '18px',
        height: 'auto',
        paddingTop: '8px',
        paddingBottom: '8px',
      }}
    >
      <Space>
        
      </Space>
      {user?.email && <ProfileMenu user={user} />}
    </AntdLayout.Header>
  )
}
