import {
  Authenticated,
  CanAccess,
  HttpError,
  Refine,
} from '@refinedev/core'
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar'
import dayjs from 'dayjs'
import { useNotificationProvider, ThemedLayoutV2, ThemedTitleV2 } from '@refinedev/antd'
import '@refinedev/antd/dist/reset.css'

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6'
import { fortuneDataProvider } from 'dataProvider'
import { AppIcon } from 'components/app-icon'
import { Login } from 'pages/login'
import { Signup } from 'pages/signup'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { authProvider } from './authProvider'
import { Header } from './components/header'
import { ColorModeContextProvider } from './contexts/color-mode'

import { TOKEN_KEY, BACKEND_URL } from './constants'
import axios, { AxiosRequestConfig } from 'axios'
import { DashboardShow } from 'pages/dashboard'

import {
  DashboardOutlined,
} from '@ant-design/icons'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { accessControlProvider } from 'accessControlProvider'

import './styles.css'

require('dayjs/locale/ru')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ru')

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    if (request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.errors?.join(', '),
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  }
)

function App() {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={fortuneDataProvider(BACKEND_URL, axiosInstance)}
            notificationProvider={useNotificationProvider}
            authProvider={authProvider(axiosInstance)}
            i18nProvider={i18nProvider}
            routerProvider={routerBindings}
            accessControlProvider={accessControlProvider}
            resources={[             
              {
                name: 'dashboard',
                list: '/dashboard',
                meta: {
                  icon: <DashboardOutlined />,
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Header={Header}
                      Title={({ collapsed }) => (
                        <ThemedTitleV2
                          collapsed={collapsed}
                          text="Fortune Wallet"
                          icon={<AppIcon />}
                        />
                      )}
                    >
                      <CanAccess>
                        <Outlet />
                      </CanAccess>
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<NavigateToResource resource="dashboard" />} />          
                <Route path="/dashboard">
                  <Route index element={<DashboardShow />} />                  
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
              </Route>
              <Route element={<Outlet />}>
                
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  )
}

export default App
