import { IAccessControlContext } from '@refinedev/core'
import { USER_KEY } from './constants'

export const accessControlProvider: Required<IAccessControlContext> = {
  options: {
    buttons: {
      enableAccessControl: true,
      hideIfUnauthorized: true,
    },
  },
  can: async ({ resource, action, params }) => {
    const userData = localStorage.getItem(USER_KEY)
    const roles = userData ? JSON.parse(userData)?.roles : []

    if (resource === 'dashboard') {
      return Promise.resolve({ can: true })
    }

    // GOD mode
    // unless dev
    if (roles.includes('admin')) {  
      return Promise.resolve({ can: true })
    }

    // GOD mode
    if (roles.includes('developer')) {
      return Promise.resolve({ can: true })
    }

    return Promise.resolve({ can: false })
  },
}
